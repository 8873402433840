
import { mapActions, mapGetters, mapMutations } from "vuex";
import offerFilters from "@/apollo/queries/offer/onlyOfferFilters";
import _ from "lodash";
import filters from "@/mixins/filters";

export default {
  mixins: [filters],

  data() {
    return {
      ready: false,
      allFilters: false,
      isVisible: {
        mark: false,
        folder: false,
        generation: false,
        gearbox: false,
        engine_type: false,
        body_type: false
      },
      switcherForAnchor: {
        mark_select_title: {
          title: "Марка",
          switch: false
        },
        folder_select_title: {
          title: "Модель",
          switch: false
        },
        generation_select_title: {
          title: "Поколение",
          switch: false
        },
        engine_type_select_title: {
          title: "Двигатель",
          switch: false
        },
        drive_type_select_title: {
          title: "Привод",
          switch: false
        },
        body_type_select_title: {
          title: "Кузов",
          switch: false
        },
        gearbox_select_title: {
          title: "КПП",
          switch: false
        }
      }
    };
  },
  mounted() {
    if (this.$route.query.fast === "true") {
      setTimeout(() => this.scrollToFilter());
    }
    if (window) {
      window.addEventListener("click", () => {
        for (let item in this.isVisible) {
          this.isVisible[item] = false;
        }
      });
    }
  },
  destroyed() {
    if (window) {
      window.removeEventListener("click", () => {
        for (let item in this.isVisible) {
          this.isVisible[item] = false;
        }
      });
    }
  },
  fetch() {
    this.getPathParams();
    this.getQueryParams();
  },
  computed: {
    ...mapGetters({
      chosen: "filters/filters/chosen",
      filters: "filters/filters/filters",
      loading: "filters/filters/loading",
      offers: "filters/filters/offers",
      loadingRange: "filters/filters/loadingRange"
    }),
    filterPrice() {
      return this.filters?.price || null;
    },
    switcherForScroll() {
      return Object.values(this.switcherForAnchor).every(
        x => x.switch === false
      );
    },
    marksList() {
      return !this.filters.mark
        ? []
        : this.filters.mark.map(mark => {
            mark.show = mark.title.toLowerCase();
            return mark;
          });
    },
    folderList() {
      return !this.filters.folder
        ? []
        : this.filters.folder
            .map(folder => {
              folder.show = folder.title.toLowerCase();
              return folder;
            })
            .sort((a, b) => (a.title > b.title ? 1 : -1));
    },
    generationList() {
      return !this.filters.generation
        ? []
        : this.filters.generation
            .map(generation => {
              generation.show = generation.title.toLowerCase();
              return generation;
            })
            .sort((a, b) => (a.title > b.title ? 1 : -1));
    },

    engine_typeList() {
      return !this.filters.engineType
        ? []
        : this.filters.engineType
            .map(engineType => {
              engineType.show = engineType.title.toLowerCase();
              return engineType;
            })
            .sort((a, b) => (a.title > b.title ? 1 : -1));
    },
    drive_typeList() {
      return !this.filters.driveType
        ? []
        : this.filters.driveType
            .map(driveType => {
              driveType.show = driveType.title.toLowerCase();
              return driveType;
            })
            .sort((a, b) => (a.title > b.title ? 1 : -1));
    },
    body_typeList() {
      return !this.filters.bodyType
        ? []
        : this.filters.bodyType
            .map(bodyType => {
              bodyType.show = bodyType.title.toLowerCase();
              return bodyType;
            })
            .sort((a, b) => (a.title > b.title ? 1 : -1));
    },
    gearboxList() {
      return !this.filters.gearbox
        ? []
        : this.filters.gearbox
            .map(gearbox => {
              gearbox.show = gearbox.title.toLowerCase();
              return gearbox;
            })
            .sort((a, b) => (a.title > b.title ? 1 : -1));
    },

    mark_select_title() {
      return (
        this.chosen.mark
          ?.map(val => val.title)
          .sort()
          .join(", ") || "Марка"
      );
    },
    folder_select_title() {
      return (
        this.chosen.folder
          ?.map(val => val.title)
          .sort()
          .join(", ") || "Модель"
      );
    },
    generation_select_title() {
      return (
        this.chosen.generation
          ?.map(val => val.title)
          .sort()
          .join(", ") || "Поколение"
      );
    },
    engine_type_select_title() {
      return (
        this.chosen.engineType
          ?.map(val => val.title)
          .sort()
          .join(", ") || "Двигатель"
      );
    },
    drive_type_select_title() {
      return (
        this.chosen.driveType
          ?.map(val => val.title)
          .sort()
          .join(", ") || "Привод"
      );
    },
    body_type_select_title() {
      return (
        this.chosen.bodyType
          ?.map(val => val.title)
          .sort()
          .join(", ") || "Кузов"
      );
    },
    gearbox_select_title() {
      return (
        this.chosen.gearbox
          ?.map(val => val.title_short_rus)
          .sort()
          .join(", ") || "КПП"
      );
    },
    showGeneration() {
      return this.chosen.mark?.length === 1 && this.chosen.folder?.length === 1;
    }
  },
  methods: {
    ...mapMutations({
      setAllChosen: "filters/filters/SET_ALL_CHOSEN",
      setReload: "reload/reload/setReload",
      setLoadingRange: "filters/filters/SET_LOADING_RANGE",
      setIsFilterClick: "filters/filters/SET_IS_FILTER_CLICK",
      unsetChosen: "filters/filters/UNSET_CHOSEN",
      setChosen: "filters/filters/SET_CHOSEN"
    }),
    ...mapActions({
      request: "filters/filters/request",
      openModal: "modal/modal-main/openModal",
      setUrl: "filters/filters/setUrl"
    }),
    getQueryParams() {
      if (this.$route.query.engine_type_id_array) {
        this.switcherForAnchor.engine_type_select_title.title = this.engine_typeList.find(
          item => item.id === Number(this.$route.query.engine_type_id_array)
        ).id;

        this.setChosen({
          key: "engineType",
          value: this.engine_typeList.find(
            item => item.id === Number(this.$route.query.engine_type_id_array)
          )
            ? [
                this.engine_typeList.find(
                  item =>
                    item.id === Number(this.$route.query.engine_type_id_array)
                )
              ]
            : null
        });
      } else {
        this.setChosen({
          key: "engineType",
          value: null
        });
      }

      if (this.$route.query.gearbox_id_array) {
        this.switcherForAnchor.drive_type_select_title.title = this.gearboxList.find(
          item => item.id === Number(this.$route.query.gearbox_id_array)
        ).id;
        this.setChosen({
          key: "gearbox",
          value: this.gearboxList.find(
            item => item.id === Number(this.$route.query.gearbox_id_array)
          )
            ? [
                this.gearboxList.find(
                  item => item.id === Number(this.$route.query.gearbox_id_array)
                )
              ]
            : null
        });
      } else {
        this.setChosen({
          key: "gearbox",
          value: null
        });
      }
      // if (this.$route.query.bodyType) {
      // 	this.chosen.bodyType = this.filters.bodyType?.find(item => item.id === Number(this.$route.query.bodyType));
      // }
      // if (this.$route.query.driveType) {
      // 	this.chosen.driveType = this.filters.driveType?.find(item => item.id === Number(this.$route.query.driveType));
      // }
      // if (this.$route.query.year_from) {
      // 	this.chosen.year = this.filters.year?.find(item => item.value === Number(this.$route.query.year_from));
      // }
      // if (this.$route.query.year_to) {
      // 	this.chosen.year = this.filters.year?.find(item => item.value === Number(this.$route.query.year_from));
      // }
      // if (this.$route.query.price_to) {
      // 	this.chosen.price = this.filters.price?.find(item => item.value === Number(this.$route.query.price_to));
      // }
      // if (this.$route.query.owner) {
      // 	this.chosen.owner = this.filters.owner?.find(item => item.id === Number(this.$route.query.owner));
      // }
    },
    getPathParams() {
      if (this.$route.params.mark) {
        this.switcherForAnchor.mark_select_title.title = this.marksList.find(
          item => item.slug === this.$route.params.mark
        )?.slug;
        if (this.switcherForAnchor.mark_select_title.title)
          this.setChosen({
            key: "mark",
            value: this.marksList.find(
              item => item.slug === this.$route.params.mark
            )
              ? [
                  this.marksList.find(
                    item => item.slug === this.$route.params.mark
                  )
                ]
              : null
          });
      } else {
        this.setChosen({
          key: "mark",
          value: null
        });
      }

      if (this.$route.params.model) {
        // this.chosen.folder = this.chosen.mark?.folders.find(item => item.slug === this.$route.params.model);
        this.switcherForAnchor.folder_select_title.title = this.folderList.find(
          item => item.slug === this.$route.params.model
        )?.slug;
        if (this.switcherForAnchor.folder_select_title.title)
          this.setChosen({
            key: "folder",
            value: this.folderList.find(
              item => item.slug === this.$route.params.model
            )
              ? [
                  this.folderList.find(
                    item => item.slug === this.$route.params.model
                  )
                ]
              : null
          });
      } else {
        this.setChosen({
          key: "folder",
          value: null
        });
      }
      if (this.$route.params.car) {
        // this.chosen.generation = this.chosen.folder?.generations.find(item => item.slug === this.$route.params.id);
        this.switcherForAnchor.generation_select_title.title = this.generationList.find(
          item => item.slug === this.$route.params.car
        )?.slug;
        if (this.switcherForAnchor.generation_select_title.title)
          this.setChosen({
            key: "generation",
            value: this.generationList.find(
              item => item.slug === this.$route.params.car
            )
              ? [
                  this.generationList.find(
                    item => item.slug === this.$route.params.car
                  )
                ]
              : null
          });
      } else {
        this.setChosen({
          key: "generation",
          value: null
        });
      }
    },
    async clearFilter() {
      await this.setChosen({ key: "mark", value: null });
      await this.setChosen({ key: "folder", value: null });
      await this.setChosen({ key: "generation", value: null });
      await this.setChosen({ key: "bodyType", value: null });
      await this.setChosen({ key: "engineType", value: null });
      await this.setChosen({ key: "gearbox", value: null });
      this.ready = false;
      await this.filterRequest({
        // TODO очищаю от пустых значений
        mark_slug_array: this.chosen?.mark
          ? [this.chosen?.mark[0]?.slug]
          : null,
        folder_slug_array: this.chosen?.folder
          ? [this.chosen?.folder[0]?.slug]
          : null,
        generation_slug_array: this.chosen?.generation
          ? [this.chosen?.generation[0]?.slug]
          : null,
        engine_type_id_array: this.chosen?.engineType
          ? [this.chosen?.engineType[0].id]
          : this.$route.query.engineType
          ? [Number(this.$route.query.engineType)]
          : null,
        body_type_id_array: this.chosen?.bodyType
          ? [this.chosen?.bodyType[0].id]
          : this.$route.query.bodyType
          ? [Number(this.$route.query.bodyType)]
          : null,
        gearbox_id_array: this.chosen?.gearbox
          ? [this.chosen?.gearbox[0].id]
          : this.$route.query.gearbox
          ? [Number(this.$route.query.gearbox)]
          : null
      });
    },
    async checkMark(mark, type) {
      this.setReload(false);
      if (type == "mark") {
        this.isVisible.mark = false;
      }
      if (type == "folder") {
        this.isVisible.folder = false;
      }
      if (type == "generation") {
        this.isVisible.generation = false;
      }
      if (type == "engineType") {
        this.isVisible.engine_type = false;
      }
      if (type == "bodyType") {
        this.isVisible.body_type = false;
      }
      if (type == "gearbox") {
        this.isVisible.gearbox = false;
      }
      await this.setChosen({ key: type, value: [mark] });
      switch (type) {
        case "mark":
          await this.setChosen({ key: "folder", value: null });
          await this.setChosen({ key: "generation", value: null });
          await this.setChosen({ key: "bodyType", value: null });
          await this.setChosen({ key: "engineType", value: null });
          await this.setChosen({ key: "gearbox", value: null });
          // if (JSON.stringify(this.$route.query) !== "{}") {
          // 	await this.$router.replace({ query: null });
          // }
          break;
        case "folder":
          await this.setChosen({ key: "generation", value: null });
          await this.setChosen({ key: "bodyType", value: null });
          await this.setChosen({ key: "engineType", value: null });
          await this.setChosen({ key: "gearbox", value: null });
          // if (JSON.stringify(this.$route.query) !== "{}") {
          // 	await this.$router.replace({ query: null });
          // }
          break;
        case "generation":
          await this.setChosen({ key: "bodyType", value: null });
          await this.setChosen({ key: "engineType", value: null });
          await this.setChosen({ key: "gearbox", value: null });
          // if (JSON.stringify(this.$route.query) !== "{}") {
          // 	await this.$router.replace({ query: null });
          // }
          break;

        default:
          break;
      }
      this.ready = false;
      await this.filterRequest({
        // TODO очищаю от пустых значений
        mark_slug_array: this.chosen?.mark
          ? [this.chosen?.mark[0]?.slug]
          : null,
        folder_slug_array: this.chosen?.folder
          ? [this.chosen?.folder[0]?.slug]
          : null,
        generation_slug_array: this.chosen?.generation
          ? [this.chosen?.generation[0]?.slug]
          : null,
        engine_type_id_array: this.chosen?.engineType
          ? [this.chosen?.engineType[0].id]
          : this.$route.query.engineType
          ? [Number(this.$route.query.engineType)]
          : null,
        body_type_id_array: this.chosen?.bodyType
          ? [this.chosen?.bodyType[0].id]
          : this.$route.query.bodyType
          ? [Number(this.$route.query.bodyType)]
          : null,
        gearbox_id_array: this.chosen?.gearbox
          ? [this.chosen?.gearbox[0].id]
          : this.$route.query.gearbox
          ? [Number(this.$route.query.gearbox)]
          : null
      });
    },
    async filterRequest(assignVariables) {
      try {
        let response = await this.request({
          query: offerFilters,
          variables: assignVariables
        });
        await this.changingFilters(response.data.offerFilters);
        this.ready = true;
        // this.setIsFilterClick(false)
      } catch (error) {
        return this.$nuxt.error({ statusCode: 404, message: "404" });
      }
    },
    async changingFilters(payload) {
      await this.$store.commit("filters/filters/SET_FILTERS", payload);
      await this.$store.commit(
        "filters/filters/SET_ALL_CHOSEN",
        payload.chosen
      );
      setTimeout(() => {
        if (payload.price) {
          this.setChosen({ key: "priceFrom", value: payload.price[0] });
          this.setChosen({ key: "priceTo", value: payload.price[1] });
        }
        if (payload.year) {
          this.setChosen({ key: "yearFrom", value: payload.year[0] - 1 });
          this.setChosen({ key: "yearTo", value: payload.year[1] });
        }
      }, 1500);
      this.setReload(true);
    },
    // openFilterModal(type) {
    // 	let modalComponent
    // 	if (type === 'mark' ||
    // 		type === 'drive-type' ||
    // 		type === 'generation' ||
    // 		type === 'engine-type' ||
    // 		type === 'body-type' ||
    // 		type === 'gearbox') {
    // 		modalComponent = `modal-filter-${type}`
    // 	}
    // 	if (type === 'folder') {
    // 		modalComponent = this.chosen.mark ? 'modal-filter-folder' : 'modal-filter-mark'
    // 	}
    // 	let payload = {
    // 		modal_component: `modal-filter`,
    // 		modal_title: 'Фильтр',
    // 		modal_data: { type: modalComponent }
    // 	}
    // 	this.openModal(payload)
    // },
    scrollToFilter() {
      const cl = document.querySelector(".catalog__filter--new");
      // const cl_top = cl?.getBoundingClientRect()?.top;
      // window.scrollTo({
      // 	top: cl_top - 100,
      // 	left: 0,
      // 	behavior:'smooth'
      // })
      // cl.scrollIntoView({ block: 'start', behavior: 'smooth' })

      // if (window.innerWidth <= 800) {
      //   window.scrollBy({
      //     top: cl_top - 60,
      //     behavior: "smooth"
      //   });
      // } else {
      //   window.scrollBy({
      //     top: cl_top - 140,
      //     behavior: "smooth"
      //   });
      // }
    },
    activeFilter() {
      this.setIsFilterClick(true);
      this.setUrl("fast");
      this.switcherForScroll && this.scrollToFilter();
    }
  },
  watch: {
    "isVisible.mark"(value) {
      if (value) {
        // this.isVisible.mark = false
        this.isVisible.folder = false;
        this.isVisible.generation = false;
        this.isVisible.gearbox = false;
        this.isVisible.engine_type = false;
        this.isVisible.body_type = false;
      }
    },
    "isVisible.folder"(value) {
      if (value) {
        this.isVisible.mark = false;
        // this.isVisible.folder = false
        this.isVisible.generation = false;
        this.isVisible.gearbox = false;
        this.isVisible.engine_type = false;
        this.isVisible.body_type = false;
      }
    },
    "isVisible.generation"(value) {
      if (value) {
        this.isVisible.mark = false;
        this.isVisible.folder = false;
        // this.isVisible.generation = false
        this.isVisible.gearbox = false;
        this.isVisible.engine_type = false;
        this.isVisible.body_type = false;
      }
    },
    "isVisible.gearbox"(value) {
      if (value) {
        this.isVisible.mark = false;
        this.isVisible.folder = false;
        this.isVisible.generation = false;
        // this.isVisible.gearbox = false
        this.isVisible.engine_type = false;
        this.isVisible.body_type = false;
      }
    },
    "isVisible.engine_type"(value) {
      if (value) {
        this.isVisible.mark = false;
        this.isVisible.folder = false;
        this.isVisible.generation = false;
        this.isVisible.gearbox = false;
        // this.isVisible.engine_type = false
        this.isVisible.body_type = false;
      }
    },
    "isVisible.body_type"(value) {
      if (value) {
        this.isVisible.mark = false;
        this.isVisible.folder = false;
        this.isVisible.generation = false;
        this.isVisible.gearbox = false;
        this.isVisible.engine_type = false;
        // this.isVisible.body_type = false
      }
    },
    $route(value) {
      this.getPathParams();
      this.getQueryParams();
      if (value.query.fast === "true") {
        setTimeout(() => this.scrollToFilter());
      }
    },
    mark_select_title(value) {
      if (value === this.switcherForAnchor.mark_select_title.switch.title) {
        this.switcherForAnchor.mark_select_title.switch = false;
      } else {
        this.switcherForAnchor.mark_select_title.switch = true;
      }
    },
    folder_select_title(value) {
      if (value === this.switcherForAnchor.folder_select_title.title) {
        this.switcherForAnchor.folder_select_title.switch = false;
      } else {
        this.switcherForAnchor.folder_select_title.switch = true;
      }
    },
    generation_select_title(value) {
      if (value === this.switcherForAnchor.generation_select_title.title) {
        this.switcherForAnchor.generation_select_title.switch = false;
      } else {
        this.switcherForAnchor.generation_select_title.switch = true;
      }
    },
    engine_type_select_title(value) {
      if (value === this.switcherForAnchor.engine_type_select_title.title) {
        this.switcherForAnchor.engine_type_select_title.switch = false;
      } else {
        this.switcherForAnchor.engine_type_select_title.switch = true;
      }
    },
    drive_type_select_title(value) {
      if (value === this.switcherForAnchor.drive_type_select_title.title) {
        this.switcherForAnchor.drive_type_select_title.switch = false;
      } else {
        this.switcherForAnchor.drive_type_select_title.switch = true;
      }
    },
    body_type_select_title(value) {
      if (value === this.switcherForAnchor.body_type_select_titletitle) {
        this.switcherForAnchor.body_type_select_title.switch = false;
      } else {
        this.switcherForAnchor.body_type_select_title.switch = true;
      }
    },
    gearbox_select_title(value) {
      if (value === this.switcherForAnchor.gearbox_select_title.title) {
        this.switcherForAnchor.gearbox_select_title.switch = false;
      } else {
        this.switcherForAnchor.gearbox_select_title.switch = true;
      }
    }
  }
};
